<template>
    <div style="min-height: calc(100vh - 158px);" class="card">
        <div style="background: #DBE7F2" class="p-2">
            <TitleButton
               btnTitle="Add New"
               :showBtn="false"
               :showAddNew="true"
               title="Money Receipt List"
               @onClickAddNewButton="onClickAddNewButton"
           />
            <div class="row match-height mt-1">
                <div class="col-12 col-lg-8 col-xl-9 col-xxl-7">
                    <DateQuerySetter
                        @onClickGo="getMoneyReceiptList"
                    />
                </div>
            </div>
        </div>
        
        <div class="card p-2" style="min-height: 200px">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Voucher Date</th>
                            <th>Party Name</th>
                            <!-- <th>Voucher No</th> -->
                            <th>Txn Ref No</th>
                            <th>Receipt Amount</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, i) in moneyReceiptList" :key="i">
                            <td class="fw-bold">{{ item.receipt_date }}</td>
                            <td>{{ item.party }}</td>
                            <!-- <td> {{ item.receipt_reference }} </td> -->
                            <td> {{ item.voucher_no }} </td>
                            <td> {{ commaFormat(item.total_paid_amount) }} </td>
<!--                            <td @click="goToEdit(item.id)"><td>-->
                            <td>
                                <button class="btn btn-primary btn-sm"
                                        @click="goToEdit(item.id)"
                                        style="cursor: pointer"
                                >
                                    <i class="fas fa-eye"></i>
                                </button>
                                <button class="btn btn-secondary btn-sm"
                                        @click="goToPrint(item.id)"
                                        style="cursor: pointer; margin-left: 10px"
                                >
                                    <i class="fas fa-print"></i>
                                </button>
                            </td>
                        </tr>
                    
                        </tbody>
                    </table>
                    <p v-if="!moneyReceiptList.length" class="text-center mt-5">No data</p>
                </div>
            </div>
        
        <div class="mb-2"></div>
        <div class="px-2 position-absolute bottom-0">
            <Pagination
                :offset="offset"
                @onPageChange="onPageChange"
                ref="pagination"
            />
        </div>
        <Loader v-if="loading"/>
    
    </div>
</template>

<script>
import handleMoneyReceipt from "@/services/moneyReceipt";
import {inject} from 'vue'
import {mapMutations, useStore} from 'vuex'

import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import ListTable from '@/components/molecule/journal/ListTable'
import Pagination from '@/components/atom/Pagination'
import Loader from '@/components/atom/LoaderComponent'
import figureFormatter from '@/services/utils/figureFormatter'


export default {
    name: 'money-receipt-list',
    
    data: () => ({
        offset: 20,
        moneyReceiptList : {}
    }),
    
    components: {
        TitleButton,
        DateQuerySetter,
        ListTable,
        Pagination,
        Loader
    },
    
    computed: {
        companyId() {
            return this.$route.params.companyId
        },
        start() {
            return this.$route.query.start
        },
        end() {
            return this.$route.query.end
        },
        page() {
            return this.$route.query.page
        },
    },
    
    setup() {
        const showError =  inject('showError');
        const showSuccess =  inject('showSuccess');
        const store = useStore()
        const {commaFormat} = figureFormatter ()
        
        const {
            fetchMoneyReceiptList,
            loading
        } = handleMoneyReceipt()
        
        return {
            fetchMoneyReceiptList,
            loading,
            showError,
            commaFormat,
            showSuccess
        }
    },
    
    methods: {
        ...mapMutations({
            setPaginationData: 'setPaginationData',
            resetPagination: 'resetPagination'
        }),

        onClickAddNewButton() {
            this.$router.push({name: 'sales-invoice-receipt', params: this.$route.params, query: this.$route.query})
        },
        
        onPageChange(page) {
            let routeQuery = Object.assign({}, this.$route.query)
            routeQuery.page = page
            this.$router.push({path: this.$route.path, query: routeQuery})
            setTimeout(() => {
                this.getMoneyReceiptList()
            },100)
        },
        
        async getMoneyReceiptList() {
            const query = this.getQuery()
            // return;
            try {
                this.loading = true
                let res = await this.fetchMoneyReceiptList(query)
                if(!res.status) {
                    this.moneyReceiptList = {}
                    this.resetPagination()
                    // this.showError(res.message)
                }
                if(res.status) {
                    if(res.data.data.length > 0) {
                        this.setPagination(res.data)
                        this.moneyReceiptList = res.data.data;
                    }
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
        },
        
        setPagination(data) {
            this.setPaginationData({
                records: data.total,
                perPage: data.per_page,
                currentPage: data.current_page,
            })
        },
        
        getQuery () {
            let query = '?company_id=' + this.companyId
            if(!this.start && !this.end) return query
            if(this.start) query += '&start_date='+this.start
            if(this.end) query += '&end_date='+this.end
            query += '&offset='+this.offset
            if(this.page) query += '&page='+this.page
            return query
        },

        goToEdit(id) {
          this.$router.push({name: 'money-receipt-edit',
            params: {
              companyId: this.$route.params.companyId,
              moduleId: this.$route.params.moduleId,
              menuId: this.$route.params.menuId,
              pageId: this.$route.params.pageId,
              receiptId: id
            },
            query: {
              start: this.$route.query.start,
              end:  this.$route.query.end
            }

          })
        },
        goToPrint(id) {
            this.$router.push({
                name: 'money-receipt-print',
                params: {
                    companyId: this.$route.params.companyId,
                    moduleId: this.$route.params.moduleId,
                    menuId: this.$route.params.menuId,
                    pageId: this.$route.params.pageId,
                    receiptId: id
                },
                query: this.$route.query
            })
        }
    },
    
    mounted() {
        this.getMoneyReceiptList()
    }
}
</script>
